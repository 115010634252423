<template>
  <div>
    <CModal :show.sync="$store.state.showModal" :no-close-on-backdrop="true" :centered="true"
      size="xl">
      <Form title="تحديث الخدمة" :formErrors="formErrors" :subcategories="subcategories" :cities="cities" :loading="loading"
        @saveData="update" :formData="form" :edit="true" :validationRules="getValidationRules()" />

        <template #footer>
          <CButton size="lg" @click="closeModal" color="danger">الغاء</CButton>
        </template>
    </CModal>
  </div>
</template>

<script>
import Form from "./Form.vue"
import { required, minLength, maxLength, email, decimal, integer, numeric } from "vuelidate/lib/validators";
export default {
  name: "Edit",
  components: {
    Form,
  },
  props: ["form", "subcategories", "cities"],
  data() {
    return {
      loading: false,
      formErrors: null,
    };
  },
  methods: {
    closeModal() {
      this.$store.commit("set", ["showModal", false]);
    },
    update(data) {
      this.loading = true;

      this.$http
        .put(`/services/${this.form.id}`, data)
        .then((res) => {
          // handle success
          if (res.data.status == 200) {
            this.$emit("updateDone");
            this.$store.commit("set", ["showModal", false]);
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
    },
    getValidationRules() {
      return {
        name: { required, min: minLength(2), max: maxLength(200) },
        description: { required, min: minLength(2), max: maxLength(4000) },
        phone: { required },
        phone_2: { numeric },
        email: { email },
        address: { required, min: minLength(2), max: maxLength(300) },
        latitude: { required, decimal },
        longitude: { required, decimal },
        city_id: { required, integer },
        status: { required },
        image: { },
        subcategories: { required },
        social_links: {},
      }
    }
  },
};
</script>

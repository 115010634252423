<template>
  <CRow>
    <CCol col="12" xl="12">
      <Spinner v-if="$store.state.loadSpinner" />

      <CCard v-else>
        <CCardHeader>
          <span>الخدمات</span>

          <CButton v-if="can('dashboard.services.store')" class="float-right" color="info" @click="
              $router.push({
                name: `Create Service`,
              })
            ">
            <CIcon name="cil-plus"></CIcon>
            <span> اضافة خدمة </span>
          </CButton>
        </CCardHeader>

        <CCardBody>
          <CRow class="mt-3">
            <CCol sm="12" md="3">
              <CInput invalid description="" placeholder="اسم المكان" autocomplete="name" v-model.trim="search.name" />
            </CCol>
            <CCol sm="12" md="3">
              <CInput invalid description="" placeholder="موبايل المكان" type="number" autocomplete="name"
                v-model.trim="search.phone" />
            </CCol>
            <CCol sm="12" md="3">
              <CButton @click="searchForm" type="button" class="btn-sm" color="primary">
                <CIcon name="cil-search" /> بحث
              </CButton>
              <CButton @click="resetForm" type="button" class=" mx-2" color="danger">
                تفريغ
              </CButton>
            </CCol>
          </CRow>
        </CCardBody>


        <CCardBody>
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col">#</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">المدينة</th>
                  <th scope="col">الهاتف</th>
                  <th scope="col">التقييم</th>
                  <th scope="col">الصورة</th>
                  <th scope="col">الاقسام</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">الاجراءات</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.name }}</td>
                  <td>{{ item.city_name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>
                    <Stars :rating="item.rate" />
                  </td>
                  <td>
                    <CImg height="60px" width="60px" :src="item.image_url" fluid />
                  </td>
                  <td>{{ showSubcategories(item.subcategories) }}</td>
                  <td>
                    <b :class="`text-${getBadge(item.status)}`">
                      {{ item.status == "1" ? "نشط" : "متوقف" }}
                    </b>
                    <br>
                    <CButton v-if="item.status == '0'" color="success" @click="updateStatus(1, item.id)">
                      تفعيل
                    </CButton>
                    <CButton v-else color="danger" @click="updateStatus(0, item.id)">
                      ايقاف
                    </CButton>
                  </td>
                  <td>
                    <CButton v-if="can('dashboard.services.update')" class="mx-1 mb-2" color="warning"
                      @click="openModal(item)">
                      <CIcon name="cil-pencil"></CIcon>
                    </CButton>
                    <CButton v-if="can('dashboard.services.upload-images')" class="mx-1 mb-2" color="primary"
                      @click="showUpload(item.id)">
                      <CIcon name="cil-image"></CIcon>
                    </CButton>
                    <CButton v-if="can('dashboard.services.show')" class="mx-1 mb-2" color="info"
                      @click="showModal(item)">
                      <CIcon name="cil-low-vision"></CIcon>
                    </CButton>
                    <CButton v-if="can('dashboard.services.destroy')" class="mx-1 mb-2" color="danger"
                      @click="deleteItem(item.id)">
                      <CIcon name="cil-trash"></CIcon>
                    </CButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <CPagination :active-page.sync="currentPage" :pages="totalPages" />
        </CCardBody>
      </CCard>
    </CCol>
    <edit v-if="openUpdate" :form="modalForm" :subcategories="subcategories" :cities="cities" @updateDone="updateDone">
    </edit>
    <div>
      <upload-images v-if="openUploadModal" :openUploadModal="openUploadModal" :id="serviceId"
        @uploadDone="uploadDone"></upload-images>
    </div>
    <service :itemData="itemData" />
  </CRow>
</template>

<script>
import Vue from 'vue';
import Stars from "../../components/Stars.vue";
import Edit from "./Edit";
import Service from "./Service.vue";
import UploadImages from "./UploadImages.vue";
export default {
  name: "Services",
  components: {
    Edit,
    Service,
    Stars,
    UploadImages
},
  data() {
    return {
      items: [],
      itemData: null,
      serviceId: null,
      openUploadModal: false,
      subcategories: [],
      cities: [],
      activePage: 1,
      currentPage: 1,
      totalPages: 0,
      limit: 10,
      modalForm: null,
      openUpdate: false,
      search: {
        name: null,
        phone: null
      }
    };
  },
  watch: {
    currentPage: {
      handler: function (val) {
        this.fetchData(val);
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
    this.fetchSubcategories();
    this.fetchCities();
  },
  methods: {
    fetchData(page = 1, query = null) {
      this.toggleLoadSpinner();

      let url = `services?per_page=15&page=${page}`;
      if (query) {
        url += query;
      }

      this.$http
        .get(url)
        .then((res) => {
          // handle success
          this.items = res.data.data.data;
          this.totalPages = res.data.data.last_page;
          this.toggleLoadSpinner();
        })
        .catch(() => {
          //
          this.toggleLoadSpinner();
        });
    },

    searchForm() {

      var query = "";
      if (this.search.name) {
        query += `&name=${this.search.name}`;
      }
      if (this.search.phone) {
        query += `&phone=${this.search.phone}`;
      }

      this.fetchData(1, query);
    },

    resetForm() {
      this.search.name = null;
      this.search.phone = null;
    },


    fetchSubcategories() {
      this.toggleLoadSpinner();
      this.$http
        .get(`/general/subcategories`)
        .then((res) => {
          // handle success
          this.subcategories = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch(() => {
          //
          this.toggleLoadSpinner();
        });
    },
    fetchCities() {
      this.toggleLoadSpinner();
      this.$http
        .get(`/general/cities`)
        .then((res) => {
          // handle success
          this.cities = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch(() => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
    getBadge(status) {
      switch (status) {
        case 1:
          return "success";
        case 0:
          return "danger";
        default:
          "primary";
      }
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    openModal(item) {
      this.openUpdate = true;
      this.modalForm = Object.assign({}, item);
      this.update = true;
      this.$store.commit("set", ["showModal", true]);
    },
    showModal(item) {
      this.itemData = Object.assign({}, item);
      this.$store.commit("set", ["showItemDetails", true]);
    },
    deleteItem(id) {
      this.$swal
        .fire({
          title: "تأكيد الحذف",
          text: "هل انت متأكد من الحذف ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم",
          cancelButtonText: "لا",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete("services/" + id)
              .then((res) => {
                // handle success
                if (res.data.status == 200) {
                  this.fetchData();
                  this.$swal.fire("تم الحذف!", "تم الحذف بنجاح.", "success");
                }
              })
              .catch((error) => {
                // handle error
                console.log(error);
              });
          }
        });
    },
    updateDone() {
      this.openUpdate = false;
      this.fetchData();
    },
    showUpload(id) {
      this.serviceId = id;
      this.openUploadModal = true;
    },
    uploadDone(data) {
      if (data) {
        const index = this.items.findIndex(object => {
          return object.id === this.serviceId;
        });
        Vue.set(this.items, index, data);
      }
      this.serviceId = null;
      this.openUploadModal = false;
    },
    showSubcategories(subcategories) {
      let subcategoriesNames = "";
      subcategories.forEach((category) => {
        subcategoriesNames += category.name + ", ";
      });
      return subcategoriesNames;
    },
    updateStatus(status, id) {
      this.toggleLoadSpinner();

      this.$http
        .put(`/services/${id}`, { status })
        .then((res) => {
          console.log(res.data);
          const index = this.items.findIndex(object => {
            return object.id === id;
          });
          Vue.set(this.items, index, res.data.data);
          this.toggleLoadSpinner();
        })
        .catch(() => {
          // handle error
          this.toggleLoadSpinner();
        });
    }
  },
};
</script>
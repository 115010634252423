<template>
  <div>
    <CModal :show.sync="$store.state.showItemDetails" :no-close-on-backdrop="true" :centered="true" size="xl">
      <CRow>
        <CCol col="12">
          <CCard v-if="itemData">
            <CCardBody>
              <CAlert color="info">
                <CRow>
                  <CCol col="2">الاسم</CCol>
                  <CCol col="10"> {{ itemData.name }} </CCol>
                </CRow>
              </CAlert>


              <CAlert color="info">
                <CRow>
                  <CCol col="2">المدينة</CCol>
                  <CCol col="10"> {{ itemData.city_name }} </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2"> العنوان </CCol>
                  <CCol col="10"> {{ itemData.address }} </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2">الموبايل</CCol>
                  <CCol col="10"> {{ itemData.phone }} <br /> {{ itemData.phone_2 }} </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2">الاقسام</CCol>
                  <CCol col="10"> {{ showSubcategories(itemData.subcategories) }} </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2">الوصف</CCol>
                  <CCol col="10"> {{ itemData.description }} </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2">التقييم</CCol>
                  <CCol col="10">
                    <Stars :rating="itemData.rate" />
                  </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2"> الايميل </CCol>
                  <CCol col="10"> {{ itemData.email }} </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2"> الموقع </CCol>
                  <CCol col="10">
                    <a :href="`https://www.google.com/maps/search/?api=1&query=${itemData.latitude},${itemData.longitude}`"
                      target="_blank">
                      اضغط هنا لفتح الموقع على خرائط جوجل
                    </a>
                  </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2"> الحالة </CCol>
                  <CCol col="10">
                    <b :class="`text-${getBadge(itemData.status)}`">
                      {{ itemData.status }}
                    </b>
                  </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2"> الصورة </CCol>
                  <CCol col="10">
                    <CImg :src="itemData.image_url" fluid />
                  </CCol>
                </CRow>
              </CAlert>

              <CAlert color="info">
                <CRow>
                  <CCol col="2"> الصور </CCol>
                  <CCol col="10">
                    <CRow>
                      <CCol v-for="image in itemData.images" :key="image.id" col="3">
                        <CImg class="mb-2" :src="image.path_url" fluid />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CAlert>

            </CCardBody>
          </CCard>
          <Spinner v-else />
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="closeModal" color="danger">الغاء</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Stars from '../../components/Stars.vue';

export default {
    name: "Service",
    props: ["itemData"],
    data() {
        return {};
    },
    created() { },
    methods: {
        closeModal() {
            // this.$emit("updateDone");
            this.$store.commit("set", ["showItemDetails", false]);
        },
        getBadge(status) {
            switch (status) {
                case 1:
                    return "success";
                case 0:
                    return "danger";
                default:
                    "primary";
            }
        },
        showSubcategories(subcategories) {
            let subcategoriesNames = "";
            subcategories.forEach((category) => {
                subcategoriesNames += category.name + ", ";
            });
            return subcategoriesNames;
        },
    },
    components: { Stars }
};
</script>

<template>
    <div class="star-rating">
        <span v-for="star in 5" :key="star" 
        :class="{ 'orange': star <= rating, 'grey': star > rating }"
        >
            ★
        </span> ({{ rating }})
    </div>
</template>

<script>
export default {
    props: {
        rating: {
            type: Number,
            default: 0
        }
    }
}
</script>


<style scoped>
.star-rating span {
    color: grey;
    font-size: 20px;
}

.orange {
    color: orange !important;
}

.grey {
    color: grey;
}
</style>
<template>
  <div>
    <CModal :show="openUploadModal" :no-close-on-backdrop="true" :centered="true" size="xl">
      <template #header>
        <h6 class="modal-title font-weight-bold">
          ارفق صور للخدمة
        </h6>
        <CButtonClose @click="closeModal" class="text-dark" />
      </template>

      <CRow>
        <CCol col="12">
          <CForm>
            <CRow class="mt-5">
              <CCol sm="12" md="12">
                <div role="group" class="form-group form-row">
                  <CCol sm="12" md="12">
                    <VueFileAgent ref="vueFileAgent" :theme="'grid'" :multiple="true" :deletable="true" :meta="true"
                      :accept="'image/*'" :maxSize="'3MB'" :maxFiles="8" :helpText="'اختار صورة او اسحب الى هنا'"
                      :errorText="{
                        type: 'نوع الملف غير صالح . الملفات من نوع صور فقط التي يتم قبولها',
                        size: 'حجم الملف اكبر من ٣ ميجا',
                      }" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)"
                      @delete="fileDeleted($event)"></VueFileAgent>
                  </CCol>
                  <div class="mt-3 text-danger" v-if="formErrors">
                    <span>{{ formErrors }}</span>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CForm>


        </CCol>
      </CRow>

      <template #footer>
        <CButton :disabled="loading" @click="update" type="button" color="success" size="md">
          <CSpinner v-if="loading" color="white" size="sm" />
          <span v-else>
            <CIcon name="cil-image" /> رفع الصور
          </span>
        </CButton>
        <CButton size="md" @click="closeModal" color="danger">الغاء</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: "UploadImages",
  props: ["id", "openUploadModal"],
  data() {
    return {
      loading: false,
      formErrors: null,
      images: []
    };
  },
  methods: {
    closeModal() {
      this.$emit("uploadDone");
    },
    update() {
      if (this.images.length == 0) {
        this.formErrors = "يجب اختيار صورة واحدة على الاقل";
        return;
      }
      this.loading = true;

      this.$http
        .post(`/services/upload-images`, {
          service_id: this.id,
          images: this.images.map((image) => image.data),
        })
        .then((res) => {
          // handle success
          if (res.data.status == 202) {
            this.$emit("uploadDone", res.data.data);
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
    },
    filesSelected: function (files) {
      if (Array.isArray(files)) {
        var validFileRecords = files.filter(
          (fileRecord) => !fileRecord.error
        );
        validFileRecords.forEach((record) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.images.push({
              name: record.file.name,
              data: reader.result,
            });
          };
          reader.readAsDataURL(record.file);
        });
      }
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    fileDeleted: function (fileRecord) {
      const index = this.images.findIndex((image) => image.name === fileRecord.name());
      if (index !== -1) {
        this.images.splice(index, 1);
      }
    },
  },
};
</script>
